<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="saveColor"
                v-model="save")
                    | {{ saveText }}
                    v-btn(flat, dark, @click.native="save = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h2 {{$t('Personalizar red')}}
                    v-divider
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-alert.my-3(v-if="config.pending" icon="info" :value="true" color="info" outline)
                            | {{ $t('Pendiente por aprobar: Los cambios estan siendo revisados por el administrador para su aprobación.') }}
                        v-form
                            v-layout(row wrap)
                                v-flex(xs12)
                                    v-text-field(:label="$t('Nombre de la red')", v-model="config.name")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto a compartir')}}
                                    v-text-field(multi-line v-model="config.text")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto de registro/inicio de sesión')}}
                                    editor(:init="$root.editor" v-model="config.logintext")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto de inicio')}}
                                    editor(:init="$root.editor" v-model="config.hometext")
                                v-flex(xs12 sm6)
                                    h3.mb-3 {{$t('Foto para inicio')}}
                                    v-btn.btn-file(color="success" outline block large) 
                                        v-icon(left) file_upload
                                        | {{$t('Subir foto')}}
                                        input(type='file' @change='fileChange($event, "shareImg", "share")')
                                    .img-file(v-if="config.shareImg.length")
                                        img(:src="config.shareImg")
                                        v-icon(@click='removeImage("shareImg", "share")') close
                                    .img-file(v-else)
                                        img(:src="config.share ? uploadurl + config.share + '?time=' + config.time : imgurl")
                                v-flex(xs12 sm6)
                                    h3.mb-3 {{$t('Foto para registro')}}
                                    v-btn.btn-file(color="success" outline block large) 
                                            v-icon(left) file_upload
                                            | {{$t('Subir foto')}}
                                            input(type='file', @change='fileChange($event, "registerImg", "register")')
                                    .img-file(v-if="config.registerImg.length")
                                        img(:src='config.registerImg')
                                        v-icon(@click='removeImage("registerImg", "register")') close
                                    .img-file(v-else)
                                        img(:src="config.register ? uploadurl + config.register + '?time=' + config.time : imgurl")
                            .btns.btns-right
                                v-btn(@click.native="saveConfig" :loading="loadingBtn" color="primary" large) {{$t('Guardar')}}
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import merge from 'lodash/merge'

    export default {
        mixins: [auth],
        metaInfo() {
            return {
                title: this.$t('Personalizar red')
            }
        },
        data(){
            return {
                save     : false,
                preload  : false,
                saveColor: 'error',
                saveText : '',
                config: {
                    name       : '',
                    text       : '',
                    hometext   : ' ',
                    logintext  : ' ',
                    time       : '',

                    // Imagen para compartir
                    shareImg  : '',
                    share     : '',

                    //Imagen de registro
                    registerImg: '',
                    register   : ''
                },
                loadingBtn: false
            }
        },
        computed: {
            imgurl(){
                return config.imgurl
            },
            uploadurl(){
                return config.uploadurl
            }
        },
        mounted(){
            this.fetch()
        },
        methods: {
            fetch(){
                this.preload = true

                this.$api(this, (xhr) => {
                    xhr.get('/custom-network/config').then((r) => {
                        let data = r.data
                        if(data.response){
                            
                            this.config = merge(this.config, data.data)
                            this.preload = false
                        }else{
                            this.preload = false
                        }
                    }).catch(() => {
                        this.preload = false
                    })
                })
            },
            saveConfig(){

                let post = this._.cloneDeep(this.config)

                if('shareImg' in post){
                    delete post.shareImg
                }

                if('registerImg' in post){
                    delete post.registerImg
                }

                if('time' in post){
                    delete post.time
                }

                let form = new FormData()
                for(let i in post){
                    form.append(i, post[i])
                }

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/custom-network/config', form).then((r) => {

                        let data = r.data
                        this.loadingBtn = false

                        this.save = true
                        this.saveText = data.message

                        if(data.response){
                            this.saveColor = 'success'
                        }else{
                            this.saveColor = 'error'
                        }
                    }).catch(() => {
                        this.loadingBtn = false
                    })
                })
            },
            fileChange(e, model, save) {

                var files = e.target.files || e.dataTransfer.files
                if (!files.length) return
                this.createImage(files[0], model, save)
            },
            createImage(file, model, save) {

                let reader = new FileReader();
                reader.onload = (e) => this.config[model] = e.target.result
                reader.readAsDataURL(file)
                this.config[save] = file
            },
            removeImage(model, save) {
                this.config[model] = ''
                this.config[save] = ''
            },
        }
    }

</script>